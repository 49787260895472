



















import Vue, { PropOptions } from 'vue';

export type Page = Blok & {
    global: GlobalReference[];
    body: Blok[];
    colorMode: string;
    background: string;
    metadata: Metadata;
    json_ld: string;
    noindex: boolean;
    v2: boolean;
    thumbnail: BlokFieldAsset;
};

export default Vue.extend({
    props: {
        blok: {
            type: Object,
            required: true,
        } as PropOptions<Page>,
    },
    data() {
        return { isHelpCenter: false, shouldShowBanner: false };
    },
    head() {
        const meta = {
            title:
                this.blok.metadata?.title ||
                'Anima | Design to code | High-fidelity prototypes | Designer-developer collaboration',
            meta: [
                {
                    name: 'robots',
                    content: this.blok.noindex ? 'noindex' : 'index',
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content:
                        this.blok.metadata?.title ||
                        'Anima | Design to code | High-fidelity prototypes | Designer-developer collaboration',
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content:
                        this.blok.metadata?.description ||
                        'Anima gives you the power to create fully interactive prototypes using Figma, XD, and Sketch & transform these designs to developer-friendly React and HTML code automatically.',
                },
                {
                    hid: 'description',
                    name: 'description',
                    content:
                        this.blok.metadata?.description ||
                        'Anima gives you the power to create fully interactive prototypes using Figma, XD, and Sketch & transform these designs to developer-friendly React and HTML code automatically.',
                },
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: this.blok.thumbnail?.filename,
                },
            ],
            script: [],
        };

        if (this.blok.json_ld) {
            meta.script.push({
                // @ts-ignore
                type: 'application/ld+json',
                // @ts-ignore
                json: JSON.parse(this.blok.json_ld),
            });
        }

        return meta;
    },
    computed: {
        headerBlok(): Blok {
            return this.blok?.global[0]?.reference?.content?.global[0];
        },
        footerBlok(): Blok {
            return this.blok?.global[1]?.reference?.content?.global[0];
        },
    },
    mounted() {
        const israelOffset = -180;
        const franceOffset = -120;

        const offset = new Date().getTimezoneOffset();
        if (israelOffset === offset || franceOffset === offset) {
            this.shouldShowBanner = false;
        }
        setTimeout(() => {
            // if it's from font ninja or css peeper navigate the user to the extension page
            const href = location.href.toString();
            if (
                location.pathname === '/' &&
                (href.includes('utm_source=chrome-extension-css-peeper') ||
                    href.includes('utm_source=chrome-extension-font-ninja'))
            ) {
                window.location.replace(
                    'https://www.figma.com/community/plugin/857346721138427857/'
                );
            }

            this.$tracking.trackEvent('website.page.view', {
                ...this.$route.query,
                path: this.$route.path,
                screenWidth: window?.innerWidth,
                isDesktop: window?.innerWidth >= 1024,
                referrer: document?.referrer,
            });
        }, 100);
    },
});
